<template>
  <default-layout>
    <panel>
      <ul>
        <li>
          <a @click="$move('/admin/env', $event)">環境情報</a>
        </li>
        <li>
          <a @click="$move('/admin/current_time', $event)">現在時刻設定</a>
        </li>
      </ul>
    </panel>
  </default-layout>
</template>

<script>
export default {
  methods: {
  }
};
</script>
